import { version, versionDate, description } from '../package.json'

export default {
	hosts: [
		{
			appauth: 'xQ8Uyf5yBL8zL6Dg',
			name: 'wisenetwork',
			domain: 'https://wisenetwork.fi',
			baseUrl: 'https://wisenetwork.fi',
			ajaxUrl: 'https://ajax.wisenetwork.fi',
			restUrl: 'https://api.wnw.fi/api/1.0/', //Change this later!
			ecomUrl: 'https://kauppa.wisenetwork.fi',
			useRestLogin: false,
			useGolfFeatures: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'WiseGym',
			displayChainName: 'WiseGym',
			className: 'wisegym-wnw',
			registerMailingLists: [1],
			customerTermsArticleId: null, 
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 9,
					textQuestionId: 5,
					contactQuestionId: 6
				}
			}
		},
		{
			appauth: 'ZEArxdAFCfXE7sSJ',
			name: 'wnw',
			domain: 'https://wnw.fi/',
			baseUrl: 'https://wnw.fi/',
			ajaxUrl: 'https://ajax.wnw.fi/',
			restUrl: 'https://api.wnw.fi/api/1.0/',
			ecomUrl: 'https://kauppa.wnw.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: true,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: true,
			useEventAddress: true,
			useEventCategoryNamePill: true,
			useSecondaryShop: false,
			useCalendarTile: false,
			useBenefits: true,
			useBookingTab: true,
			showStep2Fit: true,
			showTrainero: true,
			showStrivee: true,
			showEventUntilEndNoEnroll: true,
			showStep2FitInList: true,
			secondaryShopName: 'Lahnakauppa',
			openSecondaryShopLink: 'https://kauppa.wnw.fi',
			bookingUrl: 'https://kauppa.wnw.fi',
			displayName: 'WNW',
			displayChainName: 'WNW',
			className: 'wisegym-wnw',
			registerMailingLists: [1],
			customerTermsArticleId: 3910,
			terminateSurveyData: {
				custTerminateSurvey: {
					surveyId: 1391,
					textQuestionId: 842
				}
			},
			// Support form, these have to be updated!
			surveyHash: '40e70554',
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 470,
					textQuestionId: 637,
					contactQuestionId: 579
				}
			}
		},
		{
			appauth: '5TmX0A8F70MASvAQw',
			name: 'viregym',
			domain: 'https://viregym.fi',
			baseUrl: 'https://viregym.fi',
			ajaxUrl: 'https://ajax.viregym.fi',
			restUrl: 'https://api.viregym.fi/api/1.0/',
			ecomUrl: 'https://kauppa.viregym.fi',
			useRestLogin: false,
			useFitness: false,
			showWodConnect: false,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			minimumEntriesToShow: 5,
			displayName: 'VireGym',
			displayChainName: 'VireGym',
			className: 'wisegym-viregym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 3,
					contactQuestionId: 4
				}
			}
		},
		{
			appauth: 'eQjegrnKOTzL6Dg',
			name: 'thepark',
			domain: 'https://thepark.fi',
			baseUrl: 'https://thepark.fi',
			ajaxUrl: 'https://ajax.thepark.fi',
			restUrl: 'https://api.thepark.fi/api/1.0/',
			ecomUrl: 'https://kauppa.thepark.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'The Park',
			displayChainName: 'The Park',
			className: 'wisegym-thepark',
			registerMailingLists: [1],
			customerTermsArticleId: 13,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 1,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: '5Ekdwne70MASvAQw',
			name: 'crossfiteastturku',
			domain: 'https://crossfiteastturku.fi',
			baseUrl: 'https://crossfiteastturku.fi',
			ajaxUrl: 'https://ajax.crossfiteastturku.fi',
			restUrl: 'https://api.crossfiteastturku.fi/api/1.0/',
			ecomUrl: 'https://kauppa.crossfiteastturku.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: true,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'CrossFit East Turku',
			displayChainName: 'CrossFit East Turku',
			className: 'wisegym-crossfiteastturku',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 1903,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'ekmmEksrmWIASvAQw',
			name: 'liikuntakeskusvida',
			domain: 'https://liikuntakeskusvida.fi',
			baseUrl: 'https://liikuntakeskusvida.fi',
			ajaxUrl: 'https://ajax.liikuntakeskusvida.fi',
			restUrl: 'https://api.liikuntakeskusvida.fi/api/1.0/',
			ecomUrl: 'https://kauppa.liikuntakeskusvida.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Liikuntakeskus Vida',
			displayChainName: 'Liikuntakeskus Vida',
			className: 'wisegym-liikuntakeskusvida',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 1165,
					textQuestionId: 23,
					contactQuestionId: 24
				}
			}
		},
		{
			appauth: 'ekmmEksrmWIASvAQw',
			name: 'korsogym',
			domain: 'https://liikuntakeskusvida.fi',
			baseUrl: 'https://liikuntakeskusvida.fi',
			ajaxUrl: 'https://ajax.liikuntakeskusvida.fi',
			restUrl: 'https://api.liikuntakeskusvida.fi/api/1.0/',
			ecomUrl: 'https://korsogym.fi/kauppa',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Korso Gym',
			displayChainName: 'Korso Gym',
			className: 'wisegym-korsogym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 1165,
					textQuestionId: 23,
					contactQuestionId: 24
				}
			}
		},
		{
			appauth: 'wremEms8F70MASvAQw',
			name: 'kuntosalus',
			domain: 'https://kuntosalus.fi',
			baseUrl: 'https://kuntosalus.fi',
			ajaxUrl: 'https://ajax.kuntosalus.fi',
			restUrl: 'https://api.kuntosalus.fi/api/1.0/',
			ecomUrl: 'https://kauppa.kuntosalus.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'KuntoSalus',
			displayChainName: 'KuntoSalus',
			className: 'wisegym-kuntosalus',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 898,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'tbb8tapjkb6gcu8j3z6h',
			name: 'ladysalus',
			domain: 'https://ladysalus.fi',
			baseUrl: 'https://ladysalus.fi',
			ajaxUrl: 'https://ajax.ladysalus.fi',
			restUrl: 'https://api.ladysalus.fi/api/1.0/',
			ecomUrl: 'https://kauppa.ladysalus.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'LadySalus',
			displayChainName: 'LadySalus',
			className: 'wisegym-ladysalus',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 555,
					textQuestionId: 6,
					contactQuestionId: 7
				}
			}
		},
		{
			appauth: 'xe8w2uhjm5ezvxjvwoya',
			name: 'korttelisali',
			domain: 'https://korttelisali.fi',
			baseUrl: 'https://korttelisali.fi',
			ajaxUrl: 'https://ajax.korttelisali.fi',
			restUrl: 'https://api.korttelisali.fi/api/1.0/',
			ecomUrl: 'https://kauppa.korttelisali.fi',
			useRestLogin: false,
			useFitness: false,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			minimumEntriesToShow: 5,
			displayName: 'Korttelisali',
			displayChainName: 'Korttelisali',
			className: 'wisegym-korttelisali',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'ekekmX0A8F70MASvAQw',
			name: 'hugesports',
			domain: 'https://hugesports.fi',
			baseUrl: 'https://hugesports.fi',
			ajaxUrl: 'https://ajax.hugesports.fi',
			restUrl: 'https://api.hugesports.fi/api/1.0/',
			ecomUrl: 'https://kauppa.hugesports.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'HUGE - Huittinen Go eSports',
			displayChainName: 'HUGE - Huittinen Go eSports',
			className: 'wisegym-hugesports',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 6,
					textQuestionId: 4,
					contactQuestionId: 5
				}
			}
		},
		{
			appauth: 'parjux2j5tg66sq2exf6',
			name: 'gym23',
			domain: 'https://gym23.fi',
			baseUrl: 'https://gym23.fi',
			ajaxUrl: 'https://ajax.gym23.fi',
			restUrl: 'https://api.gym23.fi/api/1.0/',
			ecomUrl: 'https://kauppa.gym23.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Gym23',
			displayChainName: 'Gym23',
			className: 'wisegym-gym23',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'TMVKarkGuvQqXkAW',
			name: 'saloragym',
			domain: 'https://saloragym.fi',
			baseUrl: 'https://saloragym.fi',
			ajaxUrl: 'https://ajax.saloragym.fi',
			restUrl: 'https://api.saloragym.fi/api/1.0/',
			ecomUrl: 'https://kauppa.saloragym.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Salora Gym',
			displayChainName: 'Salora Gym',
			className: 'wisegym-saloragym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 542,
					textQuestionId: 240,
					contactQuestionId: 41
				}
			}
		},
		{
			appauth: '3mrxvu5ui4p6fsceg8ps',
			name: 'mmalabporvoo',
			domain: 'https://mmalab.fi',
			baseUrl: 'https://mmalab.fi',
			ajaxUrl: 'https://ajax.mmalab.fi',
			restUrl: 'https://api.mmalab.fi/api/1.0/',
			ecomUrl: 'https://kauppa.mmalab.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: true,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'MMA Lab Porvoo',
			displayChainName: 'MMA Lab Porvoo',
			className: 'wisegym-mmalabporvoo',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 57,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'ue98f9mu75rxc9nynd3y',
			name: 'crossfitpihka',
			domain: 'https://crossfitpihka.com',
			baseUrl: 'https://crossfitpihka.com',
			ajaxUrl: 'https://ajax.crossfitpihka.com',
			restUrl: 'https://api.crossfitpihka.com/api/1.0/',
			ecomUrl: 'https://kauppa.crossfitpihka.com',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			showStrivee: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Crossfit Pihka',
			displayChainName: 'Crossfit Pihka',
			className: 'wisegym-crossfitpihka',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 6,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'btgenzbi2dx27qnfcytr',
			name: 'connectfit',
			domain: 'https://connectfit.fi',
			baseUrl: 'https://connectfit.fi',
			ajaxUrl: 'https://ajax.connectfit.fi',
			restUrl: 'https://api.connectfit.fi/api/1.0/',
			ecomUrl: 'https://kauppa.connectfit.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'ConnectFit',
			displayChainName: 'ConnectFit',
			className: 'wisegym-connectfit',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'o84yy5jvfw2gh9t9kfw3',
			name: 'marswiese',
			domain: 'https://marswiese.fi',
			baseUrl: 'https://marswiese.fi',
			ajaxUrl: 'https://ajax.marswiese.fi',
			restUrl: 'https://api.marswiese.fi/api/1.0/',
			ecomUrl: 'https://kauppa.marswiese.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'Marswiese',
			displayChainName: 'Marswiese',
			className: 'wisegym-marswiese',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 20,
					textQuestionId: 17,
					contactQuestionId: 18
				}
			}
		},

		{
			appauth: 'mpn6g6wfro78qs55gfqv',
			name: 'crossfitmyrsky',
			domain: 'https://crossfitmyrsky.fi',
			baseUrl: 'https://crossfitmyrsky.fi',
			ajaxUrl: 'https://ajax.crossfitmyrsky.fi',
			restUrl: 'https://api.crossfitmyrsky.fi/api/1.0/',
			ecomUrl: 'https://kauppa.crossfitmyrsky.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: true,
			useSecondaryShop: false,
			displayName: 'Crossfit Myrsky',
			displayChainName: 'Crossfit Myrsky',
			className: 'wisegym-crossfitmyrsky',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 6,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'aq33kjhbb89jveyhe6tw',
			name: 'bbfgym',
			domain: 'https://bbfgym.fi',
			baseUrl: 'https://bbfgym.fi',
			ajaxUrl: 'https://ajax.bbfgym.fi',
			restUrl: 'https://api.bbfgym.fi/api/1.0/',
			ecomUrl: 'https://kauppa.bbfgym.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			showNumberOfPeopleInside: true,
			useTabDescriptions: false,
			useEventAddress: false,
			useSecondaryShop: false,
			displayName: 'BBFGym',
			displayChainName: 'BBFGym',
			className: 'wisegym-bbfgym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 40,
					textQuestionId: 3,
					contactQuestionId: 4
				}
			}
		},
		{
			appauth: 'SfCJn6ZbDhGvAfBvai7H',
			name: 'luvigym',
			domain: 'https://luvianjaahalli.fi',
			baseUrl: 'https://luvianjaahalli.fi',
			ajaxUrl: 'https://ajax.luvianjaahalli.fi',
			restUrl: 'https://api.luvianjaahalli.fi/api/1.0/',
			ecomUrl: 'https://kauppa.luvianjaahalli.fi',
			useRestLogin: false,
			useFitness: false,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: true,
			useSecondaryShop: false,
			displayName: 'LuviGym',
			displayChainName: 'LuviGym',
			className: 'wisegym-luvigym',
			registerMailingLists: [1],
			customerTermsArticleId: 1,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 70,
					textQuestionId: 52,
					contactQuestionId: 53
				}
			}
		},
		{
			appauth: 'jrnjJwukU3brfjN3yhe6tw',
			name: 'oasisgym',
			domain: 'https://oasisgym.fi',
			baseUrl: 'https://oasisgym.fi',
			ajaxUrl: 'https://ajax.oasisgym.fi',
			restUrl: 'https://api.oasisgym.fi/api/1.0/',
			ecomUrl: 'https://kauppa.oasisgym.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: true,
			useSecondaryShop: false,
			displayName: 'Oasis Gym & Fitness',
			displayChainName: 'Oasis Gym & Fitness',
			className: 'wisegym-oasisgym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 219,
					textQuestionId: 3,
					contactQuestionId: 4
				}
			}
		},
		{
			appauth: 'fkmWoA8F70MASvAQw',
			name: 'blocksport',
			domain: 'https://blocksport.fi',
			baseUrl: 'https://blocksport.fi',
			ajaxUrl: 'https://ajax.blocksport.fi',
			restUrl: 'https://api.blocksport.fi/api/1.0/',
			ecomUrl: 'https://kauppa.blocksport.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: true,
			useSecondaryShop: false,
			displayName: 'BlockSport',
			displayChainName: 'BlockSport',
			className: 'wisegym-blocksport',
			registerMailingLists: [1],
			customerTermsArticleId: 1,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 18,
					textQuestionId: 18,
					contactQuestionId: 19
				}
			}
		},
		{
			appauth: '5krmsafF70MASvAQw',
			name: 'fnxperformance',
			domain: 'https://fnxperformance.com',
			baseUrl: 'https://fnxperformance.com',
			ajaxUrl: 'https://ajax.fnxperformance.com',
			restUrl: 'https://api.fnxperformance.com/api/1.0/',
			ecomUrl: 'https://kauppa.fnxperformance.com',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: true,
			useSecondaryShop: false,
			displayName: 'Fnx Performance',
			displayChainName: 'Fnx Performance',
			className: 'wisegym-fnxperformance',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 10,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: '8VZNArtS94GQuD8haV4c',
			name: 'porvoonkk',
			domain: 'https://porvoonkuntoklubi.fi',
			baseUrl: 'https://porvoonkuntoklubi.fi',
			ajaxUrl: 'https://ajax.porvoonkuntoklubi.fi',
			restUrl: 'https://api.porvoonkuntoklubi.fi/api/1.0/',
			ecomUrl: 'https://kauppa.porvoonkuntoklubi.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: false,
			useSecondaryShop: false,
			displayName: 'Porvoon Kuntoklubi',
			displayChainName: 'Porvoon Kuntoklubi',
			className: 'wisegym-porvoonkk',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 9,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
		{
			appauth: 'ijrk3iejjDjndwj3jkjdfneinj',
			name: 'ahtialankuntosali',
			domain: 'https://ahtialankuntosali.fi',
			baseUrl: 'https://ahtialankuntosali.fi',
			ajaxUrl: 'https://ajax.ahtialankuntosali.fi',
			restUrl: 'https://api.ahtialankuntosali.fi/api/1.0/',
			ecomUrl: 'https://kauppa.ahtialankuntosali.fi',
			useRestLogin: false,
			useFitness: true,
			showWodConnect: true,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: false,
			useSecondaryShop: false,
			displayName: 'ahtialankuntosali',
			displayChainName: 'Ahtialan kuntosali / CHSF',
			className: 'wisegym-ahtialankuntosali',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 7,
					textQuestionId: 3,
					contactQuestionId: 4
				}
			}
		},
		{
			appauth: '5rkmj2ijndioIJ38nSvAQw',
			name: 'blackhillgym',
			domain: 'https://blackhillgym.fi',
			baseUrl: 'https://blackhillgym.fi',
			ajaxUrl: 'https://ajax.blackhillgym.fi',
			restUrl: 'https://api.blackhillgym.fi/api/1.0/',
			ecomUrl: 'https://kauppa.blackhillgym.fi',
			useRestLogin: false,
			useFitness: false,
			showWodConnect: false,
			showContractCancelButton: false,
			useTabDescriptions: false,
			useEventAddress: false,
			showNumberOfPeopleInside: false,
			useSecondaryShop: false,
			displayName: 'blackhillgym',
			displayChainName: 'Blackhill Gym',
			className: 'wisegym-blackhillgym',
			registerMailingLists: [1],
			customerTermsArticleId: 6,
			// Support form, these have to be updated!
			supportSurveyData: {
				custSupportSurvey: {
					surveyId: 5,
					textQuestionId: 2,
					contactQuestionId: 3
				}
			}
		},
	],
	isBranded: false,
	appId: "87257150",
	appVersion: `${ description } ${ version } - ${ versionDate }`, //This is just for error log. Keep the format of "version - date". Its important.
	version,
	bluetoothFallbackLocale: "fi-FI", // If some data not found in user choosed locale fallback to this locale. (Used door name, on door open)
	defaultClassName: 'wisegym',
	defaultScreenMode: 'light',

	// app features
	useGymFeatures: true,
	useGolfFeatures: false,
	useReservationWebsocket: false,
	
	// Register form
	usePersonDateOfBirth: true, // If true collects person birthday on register
	usePersonGuardian: true, // If true collects guardianName guardianBirthday on register (To use guradian settings usePersonDateOfBirth have to set true)
	usePersonGuardianContacts: true, // If true collects guardianPhone ja guardianEmail on register (To use guradian settings usePersonDateOfBirth have to set true)
	usePersonAdressInformation: true, // If true collects streetAddress, postalCode and city on register
	minAgeToRegister: 16, // User has to be at least years old to set own birthday on register form
	
	defaultPath: {
		path: '/',
		redirect: '/gym/welcome'
	},
	
	loginModalHostText: 'loginModal.gym',
	loginModalSelectText: 'loginModal.chooseGym',

	// PushNotifications
	hideNotificationSettings: false,
	// topics can be overwritten by getmobileappsettings endpoint
	// generate topics by host

	get topics() {
		const topics = {
			'wisegym-general-fi': {
				label: 'WiseGym uutiset'
			},
			'wisegym-general-en': {
				label: 'WiseGym news'
			}
		}

		for (let host of this.hosts) {
			// Finnish
			const newsKey = `${ host.name }-news-fi`
			const newsLabel = `${ host.displayName } uutiset`
			const offersKey = `${ host.name }-offers-fi`
			const offersLabel = `${host.displayName} tarjoukset`
			
			//English
			const newsKeyEn = `${ host.name }-news-en`
			const newsLabelEn = `${ host.displayName } news`
			const offersKeyEn = `${ host.name }-offers-en`
			const offersLabelEn = `${host.displayName} offers`

			//Swedish
			const newsKeySv = `${ host.name }-news-sv`
			const newsLabelSv = `${ host.displayName } nyheter`
			const offersKeySv = `${ host.name }-offers-sv`
			const offersLabelSv = `${host.displayName} erbjudande`
			
			topics[newsKey] = { label: newsLabel };
			topics[offersKey] = { label: offersLabel };
			topics[newsKeyEn] = { label: newsLabelEn };
			topics[offersKeyEn] = { label: offersLabelEn };
			topics[newsKeySv] = { label: newsLabelSv };
			topics[offersKeySv] = { label: offersLabelSv };
		}

		return topics;
	},

	get topicDefaults() {
		const topics = {
			'wisegym-news-fi': false,
			'wisegym-news-en': false
		}

		for(let host of this.hosts) {
			const newsKey = `${ host.name }-news-fi`
			const offersKey = `${host.name}-offers-fi`
			const newsKeyEn = `${ host.name }-news-en`
			const offersKeyEn = `${ host.name }-offers-en`
			const newsKeySv = `${ host.name }-news-sv`
			const offersKeySv = `${ host.name }-offers-sv`
			
			topics[newsKey] = false;
			topics[offersKey] = false;
			topics[newsKeyEn] = false;
			topics[offersKeyEn] = false;
			topics[newsKeySv] = false;
			topics[offersKeySv] = false;
		}

		return topics;
	},
	notificationRedirects: {
		news: 'today/article/',
		text: 'account/notifications/oldnotifications'
	},

	refreshEventDataMinutes: 3,
  // Validity information
	validityInformation: {
		showRoleValidity: false
	},
	// Hoax Open
	openDoorOnLocationFailure: true, //Are we using hoax open? Fakes valid open on 2nd gps/beacon failure. Only if gps/bluetooth available&authorized.
	maxTotalLocationFailures: 1, // How may times door open fails, before Hoax open.
	doorOpenLocationFailureHoaxInsideSecods: 60, // If maxTotalLocationFailures happens in this time do Hoax open.
	doorOpenLocationFailureHoaxWaitMsec: 2500, // How many milliseconds are delayed before the Hoax open is started.
	// Check-In
	onCheckInValidateLocation: true, // If true user location will be validated on check-in as normally on door open (GPS/BEACON)
	// Door open
	updatePositionAtBackgroundIntervalSeconds: 30, // How often position is updated on background when app is open.
	locationSearchTimeout: 20000,
	reuseLocationConfirmationLastMinutes: 2, // If succesful door open, how long user can re-use same validation, without needed to do confirm location
	validateLocationInsideDevLocation: true, // If true location for door open can validate from devLocation coordinates and from locationId coordinates / gym event confirm inside dec location
	// Debug
	debug: false, // Show debug information (for example door open)
	vuexLogger: false, // Vuex logger logs actions, mutations in console. (Helps debug code), (Vue-devtools chrome extension at the moment still beta for Vue 3) https://github.com/vuejs/devtools/releases
	devLocation: [
		{ lat: 61.494537003032484, lng: 21.797595883526874 },
		{ lat: 61.49195628649641, lng: 21.7971345436054 }, 
		{ lat: 61.492673173669864, lng: 21.79146971852312 }, 
		{ lat: 61.49473669241433, lng: 21.791759397078465 }  
	]
};