import axios from 'axios';
import moment from 'moment';
import locationAndBluetooth from '@/helpers/locationServicesAndBluetooth'
import commonGymFunctions from '@/helpers/commonGymFunctions'

// Used for to populate gym_common state on initial load and reset gym_common state on logout
const getGymCommonDefaultState = () => {
  return {
		fitnessData: null,
		loadingFitnessData: false,
		selectedDate: new Date(),
		ownEvents: false,
		ownEventsObject: false,
		selectedCategory: null,
		selectedFitnessLocation: null,
		appAuth:  null,
		customerTermsArticleId: null,
		entriesCount: null,
  }
}

export default {
	namespaced: true,
	state: getGymCommonDefaultState(),
	getters: {
		// Get user confirmable events, updates every time when fitnessdata is fetched
		userConfirmableEvents: (state, getters, rootState) => {
			//console.log("gym_common: getters: userConfirmableEvents")
			const userConfirmableEvents = state.fitnessData?.events.filter((event) => commonGymFunctions.methods.checkIsEventConfirmableByUser(rootState.common.settings, rootState.user?.user?.personId, event))
			if(typeof(userConfirmableEvents) === "object" && userConfirmableEvents.length > 0) {
				return userConfirmableEvents
			}
			return []
		},

		// Check if location check is required, or is inside any location, or have successful location validation in settings.fitness_eventConfirm.ifDoorOpenedInLastMinutes
		// Updates everytime when user cordinates change or successfull location validation timestamp change
		canUserConfirm: (state, getters, rootState) => {
			console.log("gym_common: getters: canUserConfirm")
			// Check if location check is needed to confirm event
			if(rootState.common.settings.fitness_eventConfirm?.requireLocationCheck !== true) {
				return true
			}

			// rootState.door.userCurrentCordinates needs to be here to getter watch this value change
			const userCurrentCordinates = rootState.door.userCurrentCordinates

			//console.log(userCurrentCordinates)

			// Check if inside valid GPS area
			const isInsideAnyLocationResponse = isInsideAnyLocation(userCurrentCordinates, rootState)
			if(isInsideAnyLocationResponse === true) {
				return true
			}

			// Check if last successful door open is in settings.fitness_eventConfirm.ifDoorOpenedInLastMinutes
			if(rootState.door.successfulLocationValidation.timestamp !== null) {
				const successfullDoorOpenDateTime = moment.unix(rootState.door.successfulLocationValidation.timestamp).format('DD.MM.YYYY HH:mm')
				const minutesFromSuccessfulDoorOpen =  Math.abs(moment(successfullDoorOpenDateTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes'))
				return minutesFromSuccessfulDoorOpen <= rootState.common.settings.fitness_eventConfirm?.ifDoorOpenedInLastMinutes
			}

			// If we are here user can not confirm event
			return false
		}
	},
	mutations: {
		mutateGymCommonStateToDefault(state) {
			Object.assign(state, getGymCommonDefaultState())
		},
		mutateFitnessData(state, fitnessData) {
			state.fitnessData = fitnessData;
			//console.log(state.fitnessData)
		},
		mutateSelectedDate(state, selectedDate) {
			state.selectedDate = selectedDate;
		},
		mutateLoadingFitnessData(state, loadingFitnessData) {
			state.loadingFitnessData = loadingFitnessData;
		},
		mutateSelectedCategory(state, selectedCategory) {
			state.selectedCategory = selectedCategory;
		},
		mutateSelectedFitnessLocation(state, selectedFitnessLocation) {
			state.selectedFitnessLocation = selectedFitnessLocation;
		},
		mutateHandleFitnessEvent(state, updatedEvent) {
			const eventIndex = state.fitnessData.events.findIndex(eventItem => eventItem.activityId === updatedEvent.activityId);
		
			if (eventIndex !== -1) {
				// Merge the existing event properties with the updated event
				state.fitnessData.events[eventIndex] = {
					...state.fitnessData.events[eventIndex], // keep existing properties
					...updatedEvent, // overwrite with updated properties
				};
			}
		},
		mutateMarkAttendance(state, data) {
			const eventIndex = state.fitnessData.events.findIndex(eventItem => eventItem.activityId === data.activityId)
			const attendeeIndex = state.fitnessData.events[eventIndex].eventAttendees.findIndex(attendee => attendee.attendeeId === data.attendeeMark.attendeeId)
			state.fitnessData.events[eventIndex].eventAttendees[attendeeIndex].markedAttendance = data.attendeeMark.markedAttendance
		},
		mutateOwnEvents(state, ownEvents) {
			state.ownEvents = ownEvents;
		},
		mutateOwnEventsObject(state, ownEventsObject) {
			state.ownEventsObject = ownEventsObject;
		},
		mutateCanUserConfirm(state, canUserConfirm) {
			state.canUserConfirm = canUserConfirm;
		},
		mutateEntriesCount(state, entriesCount) {
			state.entriesCount = entriesCount;
		}
	},

	actions: {
		async getFitnessData({ rootGetters, commit, rootState }) {

			// WiseSettings.js useFitness controls tabs and api calls
			const useFitness = rootGetters["common/activeHost"].useFitness

			if (useFitness) {
				console.log("gym_common: getFitnessData()")

				commit('mutateLoadingFitnessData', true)

				// Get fitness data, Filter events to categories
				try {
					const fitnessData = await axios.get(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&fitnessdata=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`)

					// Assign category name to each event as eventCategoryName
					fitnessData.data.events = fitnessData.data.events.map(event => {
						const category = fitnessData.data.categories.find(category => category.eventCategoryId === event.eventCategoryId);
						return {
							...event,
							eventCategoryName: category ? category.name : null
						};
					})

					// If have categories
					if (fitnessData.data.categories !== false) {
						fitnessData?.data?.categories?.forEach((category, index) => {
							fitnessData.data.categories[index].events = fitnessData.data.events
							.filter(event => event.eventCategoryId === category.eventCategoryId)
						})
					}
					
					commit('mutateFitnessData', fitnessData?.data)
					
					const ownEvents = fitnessData.data.events.filter(event => event.eventAttendees?.find(attendee => attendee?.personId === rootState.user?.user?.personId))
					//Store to state.gym_common.ownEvents
					commit('mutateOwnEventsObject', ownEvents)

					commit('mutateLoadingFitnessData', false)
				} catch (error) {
					console.error(error)
					commit('mutateFitnessData', null)
					commit('mutateLoadingFitnessData', false)
					return Promise.reject(error)
				}
			}
		},

		async getEntriesCount({rootGetters, commit}) {
			try {
				const data = await axios.get(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&getentriescount=1&appauth=${rootGetters["common/activeHost"].appauth}`)
				console.log("Entries total:",data.data);
				commit('mutateEntriesCount', data.data)
			} catch (error) {
				console.log(error)
			}

		},

		async handleFitnessEvent({ rootGetters, commit, rootState }, data) {
			const eventHandleData = {
				action: data.action,
				activityId: data.event.activityId,
				hash: data.event.hash,
				isMobile: true,
				isUserLocal: false,
				personId: rootState.user.user.personId,
				tagData: false
			}

			try {
				const { data } = await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&handleevent=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`, eventHandleData)
				if (Object.keys(data)[0] === 'error') {
					return Promise.reject(data.error)
				}
				commit('mutateHandleFitnessEvent', data.event)

				return data
			} catch (error) {
				console.error(error)
				throw(error)
			}
		},

		async markFitnessAttendance({ rootGetters, commit, rootState }, attendee) {
			const attendeeMark = {
				attendeeId: attendee.attendeeId,
				markedAttendance: attendee.markedAttendance
			}

			try {
				const { data } = await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&markattendance=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`, attendeeMark)
				if (Object.keys(data)[0] === 'error') {
					return Promise.reject(data.error)
				}
				commit('mutateMarkAttendance', { attendeeMark, activityId: attendee.activityId })

				return data
			} catch (error) {
				console.error(error)
				throw(error)
			}
		},
	}
}



// Check if user is inside any location
const isInsideAnyLocation = (userCurrentCordinates, rootState) => {
	let gpsInsideLocation = false
	if(userCurrentCordinates !== null) {
		// Check if inside any location, exit loop if inside location === true
		for (const location of rootState.door.doorAndLocationData.locations) {
			const containsLocationCheck = locationAndBluetooth.methods.containsLocationCheck(userCurrentCordinates,location.locationSettings.coordinates, rootState.common.settings.devLocation) // devLocation
			if(containsLocationCheck.isInsideArea === true || containsLocationCheck.isInsideDevArea === true) {
				gpsInsideLocation = true
				break
			}
		}
		return gpsInsideLocation
	}
	return gpsInsideLocation
}